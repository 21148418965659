<template>
  <div class="profile-section">
    <Breadcrumb :items="breadCrumbs" />
    <!-- INTESTAZIONE desktop -->
    <v-row no-gutters align="center" class="page-header d-none d-sm-flex pl-5">
      <h2>{{ $t("navbar.navigation.title") }}</h2>
    </v-row>

    <!-- INTESTAZIONE mobile -->
    <v-row
      no-gutters
      justify="center"
      align="center"
      class="page-header d-md-flex d-none pl-0"
    >
      <!-- <h2>{{ $t("navbar.navigation.title") }}</h2> -->
    </v-row>
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
import Breadcrumb from "@/components/navigation/Breadcrumb";

export default {
  name: "Profile",
  components: {
    Breadcrumb
  },
  computed: {
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const arrFiltered = pathArray.filter(el => {
        return el != null && el != "";
      });
      const breadCrumbs = [{ href: "/", text: "Home" }];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      // let lastIndexFound = 0;
      for (let i = 0; i < arrFiltered.length; ++i) {
        breadcrumb = `${breadcrumb}${"/"}${arrFiltered[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            href: breadcrumb,
            // i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            disabled: i + 1 === arrFiltered.length,
            text: this.$route.matched[i].meta.breadCrumb || arrFiltered[i]
          });
          // lastIndexFound = i;
          // breadcrumb = "";
        }
      }
      return breadCrumbs;
    }
  }
};
</script>
